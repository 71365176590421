import {
  ChannelsEnum,
  GLOBAL_LOCATION,
  isUserReviewer,
  useCountries,
  User
} from '../../api'
import React, { useEffect, useState } from 'react'
import {
  Body5,
  Body7,
  Box,
  Button,
  Checkbox,
  DateTimePicker,
  Dialog,
  DialogClose,
  RadioGroup,
  RadioItem,
  Space,
  TextLink
} from '@sefar/design-system'
import { NewsArticleSettings } from './news-details-settings'
import { convertDateToSend, MustReadBadge } from '../../components'
import { useConfigStore } from '../../state/config'

export function NewsChannelDialog({
  selectedCountries,
  isMustRead = false,
  mustReadResetDate,
  selectedChannelType,
  onUpdate
}: {
  selectedCountries: string[]
  isMustRead?: boolean
  mustReadResetDate?: string | null
  selectedChannelType?: ChannelsEnum
  onUpdate: (patch: Partial<NewsArticleSettings>) => void
}) {
  const [dialogCountries, setDialogCountries] = useState<string[]>(
    selectedCountries || []
  )
  const { me } = useConfigStore.getState()
  const isReviewer = isUserReviewer(me as User)
  const [dialogChannelType, setDialogChannelType] = useState<
    ChannelsEnum | undefined
  >(selectedChannelType)
  const [isMarkedAsMustRead, setIsMarkedAsMustRead] = useState(isMustRead)
  const [isEndDatePickerActive, setIsEndDatePickerActive] = useState(false)
  const [endDate, setEndDate] = useState<Date | undefined>(
    mustReadResetDate ? new Date(mustReadResetDate) : new Date()
  )
  useEffect(() => {
    if (mustReadResetDate) {
      setEndDate(new Date(mustReadResetDate))
    }
  }, [mustReadResetDate])

  const { countries } = useCountries()

  useEffect(() => {
    if (isMustRead) {
      setIsMarkedAsMustRead(true)
    }
  }, [isMustRead])

  const isMustReadAvailable =
    dialogCountries.length === 1 && dialogCountries.includes(GLOBAL_LOCATION)
  const renderCountrySelectBlock = () => (
    <Box>
      <Space
        mt="SM"
        css={{
          d: 'grid',
          gtc: 2,
          rowGap: '$4',
          columnGap: '$12',
          width: '100%',
          '@md': { gtc: 3 }
        }}
      >
        {[GLOBAL_LOCATION, ...(countries ?? [])].map((country: string) => (
          <Checkbox
            key={country}
            checked={dialogCountries?.includes(country)}
            onCheckedChange={(checked) =>
              setDialogCountries((prevState) =>
                checked
                  ? [...prevState, country]
                  : prevState.filter((item) => item !== country)
              )
            }
            label={country}
          />
        ))}
      </Space>
    </Box>
  )
  const renderMustReadBlock = () => (
    <Box
      css={{
        d: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        cursor: isMustReadAvailable ? 'pointer' : 'not-allowed',
        opacity: isMustReadAvailable ? 1 : 0.5
      }}
    >
      <Box css={{ d: 'flex', alignItems: 'center', gap: '$2' }}>
        <Checkbox
          onClick={() =>
            isMustReadAvailable && setIsMarkedAsMustRead(!isMarkedAsMustRead)
          }
          checked={isMarkedAsMustRead}
          disabled={!isMustReadAvailable}
        />
        <MustReadBadge />
      </Box>
    </Box>
  )
  const renderEndDateCheckbox = () => (
    <Checkbox
      key="end_date"
      checked={isEndDatePickerActive}
      disabled={!isReviewer}
      onCheckedChange={(checked) => {
        if (checked !== 'indeterminate') {
          setIsEndDatePickerActive(checked)
        }
      }}
      label="Set end date"
    />
  )
  const renderEndDatePicker = (isRequired = false) => (
    <Box css={{ mt: isRequired ? '-16px' : 0 }}>
      {isRequired && (
        <Body7 css={{ mb: '$1', color: '$neutralLighten25' }}>
          Set end date{' '}
          {isRequired && (
            <Box as="span" css={{ color: '$primaryRed' }}>
              *
            </Box>
          )}
        </Body7>
      )}
      <DateTimePicker
        id="must-read-reset"
        initialValue={endDate}
        value={endDate}
        onChange={(date) => {
          setEndDate(date)
        }}
        min={new Date()}
        autoFocus={false}
      />
    </Box>
  )

  return (
    <Dialog
      onOpenChange={(open) => {
        if (!open) {
          setDialogCountries([])
          setDialogChannelType(undefined)
        } else {
          setDialogCountries(selectedCountries || [])
          setDialogChannelType(selectedChannelType)
        }
      }}
      title="News Channel"
      trigger={<TextLink>{selectedChannelType ? 'Edit' : 'Select'}</TextLink>}
      footer={
        <Space css={{ d: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => {
              const patchParams: Partial<NewsArticleSettings> = {
                field_location:
                  dialogChannelType === ChannelsEnum.company_focus
                    ? []
                    : dialogCountries,
                channelType: dialogChannelType
              }
              if (isMustReadAvailable) {
                patchParams.field_news_must_read = isMarkedAsMustRead
              }
              if (
                isEndDatePickerActive ||
                dialogChannelType === ChannelsEnum.announcements
              ) {
                patchParams.unpublishOn =
                  endDate instanceof Date ? convertDateToSend(endDate) : null
              }

              onUpdate(patchParams)
            }}
            disabled={
              dialogChannelType !== ChannelsEnum.company_focus &&
              !dialogCountries?.length
            }
            as={DialogClose}
          >
            Save
          </Button>
        </Space>
      }
    >
      <Space
        css={{
          maxHeight: '100%',
          pr: '$4',
          mb: '$4',
          color: '$neutral'
        }}
      >
        <Body5>To which channels do you want to publish the article?</Body5>
      </Space>

      <RadioGroup
        id="channels type"
        value={dialogChannelType}
        onValueChange={(type: ChannelsEnum) => {
          setDialogChannelType(type)
          setIsEndDatePickerActive(type === ChannelsEnum.announcements)
        }}
        aria-label="channels type"
        css={{
          d: 'flex',
          flexDirection: 'column',
          gap: '$4'
        }}
      >
        <RadioItem
          id="company_focus"
          value={ChannelsEnum.company_focus}
          label="Company focus"
          disabled={!isReviewer}
        />
        {dialogChannelType === ChannelsEnum.company_focus && (
          <Space mb="SM" css={{ d: 'flex', alignItems: 'center', gap: '$8' }}>
            {renderEndDateCheckbox()}
            {isEndDatePickerActive && renderEndDatePicker()}
          </Space>
        )}

        <RadioItem
          id="announcements"
          value={ChannelsEnum.announcements}
          label="Announcement"
        />
        {dialogChannelType === ChannelsEnum.announcements && (
          <>
            <Space mt="SM" css={{ d: 'flex', alignItems: 'center', gap: '$8' }}>
              {renderEndDatePicker(true)}
              {renderMustReadBlock()}
            </Space>
            {renderCountrySelectBlock()}
          </>
        )}

        <RadioItem id="stories" value={ChannelsEnum.stories} label="Story" />
        {dialogChannelType === ChannelsEnum.stories && (
          <>
            <Box css={{ d: 'flex', alignItems: 'center', gap: '106px' }}>
              {renderEndDateCheckbox()}
              {renderMustReadBlock()}
            </Box>
            {isEndDatePickerActive && renderEndDatePicker()}
            {renderCountrySelectBlock()}
          </>
        )}
      </RadioGroup>
    </Dialog>
  )
}
