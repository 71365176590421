import {
  Body4,
  Box,
  ChevronDownIcon16,
  Container,
  ContainerPadding,
  Grid
} from '@sefar/design-system'
import React, { useState, useRef, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useHomePageBgImage, ChannelsEnum } from '../../api'
import { useDebounce, useMedia } from 'react-use'
// @ts-ignore
import { BREAKPOINTS_QUERIES } from '../../../../../../stitches.config'
import { HomeSearch } from './home-search'
import { HomeNewsPreview } from './home-news-preview'
import { ToolsBar } from '../../components/tools/tools-bar'
import {
  analyticsPageTracker,
  Footer,
  getCurrentTime,
  mixpanelAnalyticsHelper
} from '../../components'
import { localStorageKeys } from '../../constants'
import { useConfigStore } from '../../state/config'
import HomeFilters, { NewsFilters } from './home-filters'
import HomeNewsBlock from './home-news-block'
import HomeItUpdates from './home-it-updates'
import HomeCompanyFocus from './home-company-focus'

export function Home({ bgImageMeta }: { bgImageMeta: any }) {
  const { me } = useConfigStore()
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentTime] = useState(getCurrentTime())
  const [filters, setFilters] = useState<NewsFilters>({})
  const [excludedArticles, setExcludedArticles] = useState<string[]>([])
  const { bgImage } = useHomePageBgImage(me?.id ? currentTime : undefined)
  const newsTopRef = useRef<HTMLDivElement>(null)
  const [focused, setFocused] = useState(false)
  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)
  const isMd = useMedia(BREAKPOINTS_QUERIES.md)
  const myHomeFilters = useMemo<NewsFilters>(
    () => ({
      country: filters.country
    }),
    [filters.country]
  )
  useDebounce(
    () => {
      analyticsPageTracker()
      mixpanelAnalyticsHelper().time('Leaving from home page')
      mixpanelAnalyticsHelper().track('Visit home page')
    },
    2000,
    []
  )

  const onCountryChange = (country: string) => {
    const updatedFilters = {
      ...filters,
      country
    }
    if (newsTopRef.current) {
      window.scrollTo({
        top: newsTopRef.current.offsetTop - 64,
        behavior: 'smooth'
      })
    }
    setFilters({ ...updatedFilters })
    localStorage.setItem(localStorageKeys.NEWS_CHOSEN_COUNTRY, country)
    searchParams.set('country', country)
    setSearchParams(searchParams)
  }

  const onChannelChange = (channel: string) => {
    const updatedFilters = {
      ...filters,
      channel
    }
    if (newsTopRef.current) {
      window.scrollTo({
        top: newsTopRef.current.offsetTop - 64,
        behavior: 'smooth'
      })
    }
    setFilters({ ...updatedFilters })
    searchParams.set('channel', channel)
    setSearchParams(searchParams)
  }

  return (
    <Box
      css={{
        d: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box
        css={{
          position: 'relative',
          backgroundPosition: 'center c]enter',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundImage: `url(${bgImage?.thumbnails['2048px']})`,
          height: '100vh',
          marginTop: '-$16',
          paddingTop: '$16',
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          transition: 'none',
          '@lg': {
            minHeight: '760px'
          }
        }}
      >
        <Box
          css={{
            background:
              'linear-gradient(180deg, rgba(26, 48, 65) 0%, rgba(0, 25, 42) 100%)',
            opacity: focused ? 0.8 : 0.5,
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 1,
            top: 0,
            transition: 'opacity .3s',
            pointerEvents: 'none'
          }}
        ></Box>
        <Box
          css={{
            my: 'auto',
            d: 'flex',
            pt: '2vh',
            flexDirection: 'column',
            overflow: 'hidden'
          }}
        >
          <ContainerPadding
            css={{
              '@sm': focused
                ? {
                    mt: 0,
                    top: -80
                  }
                : {}
            }}
          >
            <Container
              css={{
                position: 'relative',
                width: '85vw',
                '@lg': { width: 'auto' }
              }}
              adaptiveTablet
            >
              <Grid
                css={{
                  columnGap: '$2',
                  '@xl': {
                    columnGap: '$5'
                  }
                }}
              >
                {isMd && <HomeSearch focused={focused} />}
                <ToolsBar setFocused={setFocused} />
              </Grid>
            </Container>
          </ContainerPadding>

          {isLg ? (
            <ContainerPadding css={{ flex: 1, mt: '2vh', overflow: 'hidden' }}>
              <Container css={{ h: '100%' }}>
                <HomeNewsPreview setExcluded={setExcludedArticles} />
              </Container>
            </ContainerPadding>
          ) : (
            <Box css={{ w: '100%', mt: '$7', '@md': { mt: '$16' } }}>
              <HomeNewsPreview setExcluded={setExcludedArticles} />
            </Box>
          )}
          <Box
            css={{
              position: 'relative',
              zIndex: 1,
              mt: '30px',
              color: '$white',
              cursor: 'pointer',
              d: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '$1',
              '@lg': { mt: '2vh', flexDirection: 'column-reverse' }
            }}
            onClick={() => newsTopRef.current?.scrollIntoView()}
          >
            <ChevronDownIcon16 width={24} height={24} />
            <Body4 fontWeight="bold">See all news</Body4>
          </Box>
        </Box>
        <Box>
          <Footer variant="dark" bgImageMeta={bgImageMeta} />
        </Box>
      </Box>
      <Box css={{ minHeight: 2000, position: 'relative' }} ref={newsTopRef}>
        <HomeFilters
          onFiltersChange={(filters) => {
            if (newsTopRef.current) {
              window.scrollTo({
                top: newsTopRef.current.offsetTop - 64,
                behavior: 'smooth'
              })
            }
            setFilters({ ...filters })
          }}
          onFiltersInit={setFilters}
          filters={filters}
        />

        <Box
          css={{
            d: filters.channel === ChannelsEnum.all ? 'block' : 'none'
          }}
        >
          <HomeCompanyFocus filters={filters} exclude={excludedArticles} />
          <ContainerPadding>
            <Container css={{ mb: '$16' }}>
              <HomeNewsBlock
                pageSize={2}
                css={{ mb: '$16' }}
                exclude={excludedArticles}
                filters={myHomeFilters}
                onCountryChange={onCountryChange}
                onChannelChange={onChannelChange}
              />
              <HomeItUpdates
                css={{ mb: '$16' }}
                exclude={excludedArticles}
                onNavigate={() => {
                  if (newsTopRef.current) {
                    window.scrollTo({
                      top: newsTopRef.current.offsetTop - 64,
                      behavior: 'smooth'
                    })
                  }
                  setFilters({ ...filters, category: ['IT Updates'] })
                }}
              />
              <HomeNewsBlock
                exclude={excludedArticles}
                pagination
                offset={2}
                pageSize={10}
                initialSize={4}
                filters={myHomeFilters}
                archiveLink
                onCountryChange={onCountryChange}
                onChannelChange={onChannelChange}
              />
            </Container>
          </ContainerPadding>
        </Box>
        <Box
          css={{
            d: filters.channel === ChannelsEnum.all ? 'none' : 'block'
          }}
        >
          <ContainerPadding>
            <Container size="medium" css={{ pt: 40, pb: 80 }}>
              <HomeNewsBlock
                filters={filters}
                pagination
                exclude={excludedArticles}
                archiveLink
                onCountryChange={onCountryChange}
                onChannelChange={onChannelChange}
              />
            </Container>
          </ContainerPadding>
        </Box>
      </Box>
    </Box>
  )
}
