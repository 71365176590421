import { useMemo, useEffect, useState, useCallback } from 'react'
import { checkIsWebUri } from '../../utils/url'
import { getFaviconUrl } from '../utils'
import {
  useDefaultTools,
  addBookmark,
  Bookmark,
  Tool,
  getToolIconByUrl,
  patchUserTools,
  transferTool
} from '../../api'
import { useConfigStore } from '../../state/config'
import update from 'immutability-helper'

import {
  Box,
  Button,
  Heading5,
  Space,
  Body5,
  Dialog,
  Input,
  Spinner,
  useToastContext,
  ToastTypeEnum,
  AddToolCardButton,
  EditToolCardButton,
  CloseEditCardButton,
  ToolCardType,
  DraggableToolCard,
  TrashcanIcon24,
  SearchIcon20,
  Body7
} from '@sefar/design-system'
import { useTranslate } from '../../hooks/useTranslate'
import { useDrop, useDragLayer } from 'react-dnd'
import { useMedia } from 'react-use'
import { BREAKPOINTS_QUERIES } from '../../../../stitches.config'
import { useNavigate } from 'react-router-dom'

const bookmarkInitialState = {
  link: '',
  name: ''
}
type Props = {
  setFocused: (focused: boolean) => void
}

export function ToolsBar(props: Props) {
  const { me: user, setMe, translations } = useConfigStore()
  const { defaultTools, isLoading } = useDefaultTools()
  const { setFocused } = props
  const setToast = useToastContext()
  const { t } = useTranslate()
  const isMd = useMedia(BREAKPOINTS_QUERIES.md)

  const [tools, setTools] = useState<Tool[]>([])

  const [isEditMode, setIsEditMode] = useState(false)
  const [addBookmarkRequestInProgress, setAddBookmarkRequestInProgress] =
    useState<boolean>(false)
  const [updateToolsRequestInProgress, setUpdateToolsRequestInProgress] =
    useState<boolean>(false)
  const [newBookmark, setNewBookmark] = useState({ ...bookmarkInitialState })
  const [isAddBookmarkDialogOpened, setIsAddBookmarkDialogOpened] =
    useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    setFocused(isEditMode)
  }, [isEditMode])

  const removeTool = (id: string) => {
    const updatedTools = tools.filter((tool) => {
      return tool.id !== id
    })
    setTools(updatedTools)
    if (user?.id) {
      patchUserTools(user.id, updatedTools)
    }
  }
  const [collectedProps, trashbinRef] = useDrop(
    () => ({
      accept: ToolCardType.OTHER,
      drop: (item: Tool) => {
        removeTool(item.id)
      },
      collect: (monitor) => {
        return {
          isOver: monitor.isOver()
        }
      }
    }),
    [tools]
  )
  const { isDragging } = useDragLayer((monitor) => {
    return {
      isDragging: monitor.isDragging()
    }
  })

  useEffect(() => {
    if (user?.tools) {
      setTools(user.tools)
    }
  }, [user?.tools])

  const changeNewBookmark = (propName: string, value: string) => {
    setNewBookmark((old: Bookmark) => ({ ...old, [propName]: value }))
  }

  const newBookmarkIconUrl = useMemo(() => {
    return getToolIconByUrl(newBookmark.link)
  }, [newBookmark.link])

  const onSaveBookmarkClick = () => {
    if (newBookmark.link && checkIsWebUri(newBookmark.link)) {
      setAddBookmarkRequestInProgress(true)
      addBookmark(newBookmark)
        .then((res) => res.json())
        .then((res) => {
          if (res?.errors?.length) {
            setToast({
              message: t('field_error_tool_save_toast'),
              type: ToastTypeEnum.error
            })
          } else {
            setToast({
              message: t('field_success_tool_add_toast'),
              type: ToastTypeEnum.success
            })
            if (res?.data) {
              const savedBookmark = transferTool(res?.data)
              addToolToScreen(savedBookmark)
            }
          }
        })
        .finally(() => {
          setAddBookmarkRequestInProgress(false)
          setIsAddBookmarkDialogOpened(false)
          setNewBookmark({ ...bookmarkInitialState })
        })
    }
  }

  const addToolToScreen = (tool: Tool) => {
    setTools((old: Tool[]) => [...old, { ...tool }])
  }

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = tools[dragIndex]
      const updatedTools = update(tools, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard]
        ]
      })
      setTools(updatedTools)
    },
    [tools, user]
  )

  const onCardDrop = useCallback(() => {
    if (user?.id) {
      patchUserTools(user?.id, tools)
    }
  }, [tools, user])

  const bookmarkUrlIsValid = useMemo(() => {
    return checkIsWebUri(newBookmark.link)
  }, [newBookmark.link])

  return (
    <Box
      css={{
        zIndex: '$toolBar',
        gc: 'span 12',
        '@md': {
          gc: 'span 8'
        },
        '@lg': {
          gc: 'span 6'
        },
        '@xl': {
          gc: 'span 4'
        }
      }}
    >
      <Box
        css={{
          position: 'relative',
          zIndex: '3',
          d: 'flex',
          justifyContent: tools.length > 2 ? 'space-between' : 'flex-start',
          gap: '$4 $6',
          flexWrap: 'wrap',
          alignItems: 'flex-start',
          '@md': {
            justifyContent: 'flex-start'
          },
          '@xl': {
            gap: '$4 $5',
            justifyContent: 'flex-start'
          },
          w: '100%'
        }}
      >
        {!isMd && (
          <Box onClick={() => navigate('/search')}>
            <Box
              css={{
                position: 'relative',
                zIndex: 1,
                backgroundColor: 'white',
                borderRadius: 10,
                d: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: 65,
                height: 65,
                '& svg': {
                  fill: '$neutralLighten50',
                  '& path': {
                    fill: '$neutralLighten50'
                  }
                }
              }}
            >
              <SearchIcon20 />
            </Box>
            <Body7
              css={{
                maxWidth: '100%',
                textOverflow: 'ellipsis',
                textAlign: 'center',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                my: '$2',
                color: '$white'
              }}
            >
              Search
            </Body7>
          </Box>
        )}

        {isLoading ? (
          <Spinner />
        ) : tools ? (
          <>
            {tools.length > 0 &&
              tools
                .slice(0, 3)
                .map((item: Tool, index: number) => (
                  <DraggableToolCard
                    key={item.id}
                    id={item.id}
                    index={index}
                    cardType={ToolCardType.OTHER}
                    onCardDrop={onCardDrop}
                    moveCard={moveCard}
                    name={item.name}
                    link={item.link}
                    iconUrl={getFaviconUrl(item.link)}
                  />
                ))}
            {isEditMode ? (
              <CloseEditCardButton onClick={() => setIsEditMode(false)} />
            ) : (
              <EditToolCardButton onClick={() => setIsEditMode(true)} />
            )}
          </>
        ) : (
          <AddToolCardButton
            onClick={() => setIsEditMode(true)}
            translations={translations}
          />
        )}
      </Box>
      {isEditMode && (
        <Box
          css={{
            d: 'flex',
            position: 'absolute',
            flexDirection: 'column',
            bottom: '-24px',
            left: 0,
            transform: 'translateY(100%)',
            background: '$cardBackgroundBlur',
            w: '100%',
            zIndex: '2',
            p: '$6',
            borderRadius: '$12',
            backdropFilter: 'blur(15px)',
            minHeight: '250px',
            '@md': {
              minHeight: '324px'
            }
          }}
        >
          <Box css={{ position: 'absolute' }}>
            <Heading5 css={{ color: '$white' }}>
              {tools.length > 3
                ? t('field_tools_all_tools_text')
                : t('field_tools_add_tools_text')}
            </Heading5>
          </Box>
          <Box
            css={{
              d: 'flex',
              pt: tools.length < 3 || updateToolsRequestInProgress ? 0 : '$13',
              px: '$2',
              flexWrap: 'wrap',
              w: '100%',
              mb: '$6',
              alignItems: 'flex-start',
              justifyContent: 'center',
              gap: '$3',
              '@lg': {
                justifyContent: 'flex-start',
                gap: '$6'
              }
            }}
          >
            {updateToolsRequestInProgress ? (
              <Spinner />
            ) : (
              <>
                {tools.length > 3 &&
                  tools
                    .slice(3)
                    .map((item: Tool, index: number) => (
                      <DraggableToolCard
                        key={item.id}
                        id={item.id}
                        index={index + 3}
                        onCardDrop={onCardDrop}
                        name={item.name}
                        link={item.link}
                        cardType={ToolCardType.OTHER}
                        moveCard={moveCard}
                        iconUrl={getFaviconUrl(item.link)}
                      />
                    ))}
                <AddToolCardButton
                  onClick={() => setIsAddBookmarkDialogOpened(true)}
                  translations={translations}
                />
              </>
            )}
          </Box>
          <Box
            css={{
              opacity: isDragging ? 1 : 0,
              border: '2px dashed #FFFFFF',
              height: 103,
              width: '100%',
              borderRadius: 8,
              position: 'relative',
              zIndex: '3',
              mt: '$10',
              d: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              w: '100%'
            }}
            ref={trashbinRef}
          >
            <TrashcanIcon24 />
            <Body5 css={{ color: '#ffffff', mt: '$2' }}>
              Drop item to remove from list
            </Body5>
          </Box>
        </Box>
      )}
      <Dialog
        title={t('field_tools_add_link_text')}
        setOpenControlledDialog={setIsAddBookmarkDialogOpened}
        open={isAddBookmarkDialogOpened}
        contentProps={{ css: { '@md': { width: 550 } } }}
        innerContentCss={{ '@md': { pt: 0, pb: '$2' } }}
        footer={
          <Space
            css={{
              d: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Button
              variant="secondary"
              onClick={() => setIsAddBookmarkDialogOpened(false)}
              css={{ mr: '$3' }}
            >
              {t('field_cancel')}
            </Button>
            <Button
              disabled={!bookmarkUrlIsValid || !newBookmark.name}
              onClick={onSaveBookmarkClick}
            >
              {t('field_save')}
            </Button>
          </Space>
        }
      >
        <Space
          my="MD"
          css={{
            d: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            gap: '$6'
          }}
        >
          {addBookmarkRequestInProgress ? (
            <Spinner />
          ) : (
            <>
              <Box css={{ position: 'relative' }}>
                <Space mb="SM">
                  <Body5>{t('field_tools_add_description_text')}</Body5>
                </Space>
                <Input
                  label="URL*"
                  placeholder={t('field_tools_url_input_plchldr')}
                  value={newBookmark.link}
                  onChange={(e) => changeNewBookmark('link', e.target.value)}
                  startEnhancer={
                    bookmarkUrlIsValid ? (
                      <Box
                        css={{
                          width: '24px',
                          height: '24px',
                          d: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <Box
                          css={{ maxWidth: '100%', maxHeight: '100%' }}
                          as="img"
                          src={newBookmarkIconUrl || undefined}
                          alt="logo"
                        />
                      </Box>
                    ) : null
                  }
                />
              </Box>
              <Input
                label={`${t('field_tools_name_input_label')}*`}
                placeholder={t('field_tools_name_input_plchldr')}
                value={newBookmark.name}
                onChange={(e) => changeNewBookmark('name', e.target.value)}
              />
            </>
          )}
        </Space>
      </Dialog>
    </Box>
  )
}
