import React, { ComponentProps, useMemo } from 'react'
// @ts-ignore
import Clamp from 'react-multiline-clamp'
import { Link } from 'react-router-dom'
import { useMedia } from 'react-use'
import {
  Body5,
  Body6,
  Box,
  Heading2,
  LazyImage,
  Space
} from '@sefar/design-system'
import { ChannelsEnum, NewsArticle } from '../../api'
import {
  analyticsPageTracker,
  ChannelBadge,
  mixpanelAnalyticsHelper,
  MustReadBadge
} from '../../components'
import { AuthorCard } from '../../components/common/author'
import { CommentsCounter } from '../../components/common/comments-counter'
import { ViewsCounter } from '../../components/common/views-counter'
import { LikesCounter } from '../../components/article/article-info'
import { LocationBadge } from '../../components/news/location-badge'
import { styled } from '../../../../../../stitches.config'

const ArticleLink = styled(Link, {
  all: 'unset',
  transition: '$default',
  d: 'flex',
  cursor: 'pointer',
  width: '100%',
  height: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '$4'
})

export function NewsArticleHomePreview({
  article,
  isLarge = false,
  isMobile = false,
  variant = 'homepage',
  onCountryChange,
  onChannelChange,
  isCountryHidden = false,
  isChannelHidden = false,
  ...props
}: {
  article: NewsArticle
  isLarge?: boolean
  isMobile?: boolean
  variant?: 'homepage' | 'feed'
  onCountryChange?: (country: string) => void
  onChannelChange?: (channel: string) => void
  isCountryHidden?: boolean
  isChannelHidden?: boolean
} & Omit<ComponentProps<typeof ArticleLink>, 'to'>) {
  const isSmallHeight = useMedia('(max-height: 800px)')
  const TitleComponent = isLarge ? Heading2 : Body5

  const desktopTitleLines = isSmallHeight ? 1 : isLarge ? 3 : 2
  const titleLines = isMobile ? (isLarge ? 3 : 4) : desktopTitleLines
  const imageAspectRatio = isMobile ? '' : isLarge ? '16 / 9' : '4 / 3'
  const theme = useMemo(() => {
    if (variant === 'homepage') {
      return {
        date: isMobile && isLarge ? '$white' : '$neutralLighten80',
        author: '$white !important',
        cardHover: {
          '&:hover > div:first-child > img': {
            transform: 'scale(1.05)'
          },
          '&:hover > div:nth-child(2)': {
            transform: 'translateY(-5px)'
          }
        }
      }
    } else if (variant === 'feed') {
      return {
        date: '$neutral',
        author: '$neutral',
        cardHover: {
          '&:hover': {
            boxShadow: '$cardHover'
          }
        }
      }
    }
  }, [variant, isMobile, isLarge])
  return (
    <ArticleLink
      to={`/news/${article.id}`}
      {...props}
      onClick={() => {
        mixpanelAnalyticsHelper().track('Leaving from home page')
        analyticsPageTracker('news')
      }}
      css={{
        d: 'flex',
        flexDirection: isMobile && isLarge ? 'column' : '',
        alignItems: isMobile && isLarge ? 'flex-start' : '',
        gap: isMobile && !isLarge ? '$5' : '$7',
        maxHeight: !isMobile && !isLarge ? 'calc(100% - 37px)' : 'none',
        overflow: 'hidden',
        ...theme?.cardHover
      }}
    >
      <Box
        css={{
          flex: isMobile && !isLarge ? undefined : 1,
          alignSelf: 'stretch',
          width: isMobile && !isLarge ? 'calc(50% - 20px)' : undefined,
          overflow: 'hidden'
        }}
      >
        <LazyImage
          src={article.previewMedia}
          aspectRatio={imageAspectRatio}
          css={{
            borderRadius: isLarge && isMobile ? '0' : '$4'
          }}
        />
      </Box>
      <Space
        py="2XS"
        css={{
          flex: isMobile ? undefined : 1,
          d: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          p: isMobile && isLarge ? '0 $4 30px' : undefined,
          width: isMobile && !isLarge ? '50%' : undefined
        }}
      >
        <Box
          css={{
            d: 'flex',
            alignItems: 'center',
            gap: '9px'
          }}
        >
          <Body6
            css={{
              color: theme?.date,
              textOverflow: 'ellipsis',
              textWrap: 'nowrap',
              overflow: 'hidden'
            }}
          >
            {article.shortDate}
          </Body6>
          {variant === 'feed' && (
            <>
              {article.isMustRead && (
                <MustReadBadge
                  css={{
                    px: '$2',
                    py: '1px',
                    flexShrink: 0,
                    '& div': { fontSize: '11px !important' }
                  }}
                />
              )}
              {!isCountryHidden && (
                <LocationBadge
                  locationName={article.location[0]}
                  onClick={() => onCountryChange?.(article.location[0])}
                />
              )}
              {!isChannelHidden && (
                <ChannelBadge
                  channelKey={article.channels[0] as ChannelsEnum}
                  onClick={() => onChannelChange?.(article.channels[0])}
                />
              )}
            </>
          )}
        </Box>
        <TitleComponent
          fontWeight="bold"
          css={{
            mt: isLarge ? '$2' : 0,
            wordBreak: 'break-word'
          }}
        >
          <Clamp lines={titleLines} maxLines={Number.MAX_SAFE_INTEGER}>
            {article.title}
          </Clamp>
        </TitleComponent>
        {isLarge && (
          <Space
            mt="MD"
            css={{
              d: 'flex',
              '& div': { color: theme?.author }
            }}
          >
            <AuthorCard
              id={article?.author?.id}
              image={article?.author?.image}
              firstName={article?.author?.firstName}
              lastName={article?.author?.lastName}
              jobTitle={article?.author?.jobTitle}
              status={article?.author?.status}
              css={{ '&:hover': { background: '$cardBackgroundBlur' } }}
            />
            {variant === 'feed' && (
              <Box
                css={{
                  ml: 'auto',
                  d: 'flex',
                  gap: '$4',
                  pr: '$8',
                  alignItems: 'center'
                }}
              >
                {!article.likesDisabled && <LikesCounter article={article} />}
                <CommentsCounter count={article?.commentCount} />
                <ViewsCounter count={article?.viewers ?? 0} />
              </Box>
            )}
          </Space>
        )}
      </Space>
    </ArticleLink>
  )
}
