import {
  Body3,
  Box,
  Button,
  Container,
  ContainerPadding,
  Spinner,
  TextLink
} from '@sefar/design-system'
import { CSS } from '@stitches/react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useMedia } from 'react-use'
import { BREAKPOINTS_QUERIES } from '../../../../stitches.config'
import { NewsArticle, SEARCH_PAGE_LIMIT, useNewsInfinite } from '../../api'
import { searchPageUrl } from '../../app'
import { NewsArticleCard } from '../../components'
import { useTranslate } from '../../hooks/useTranslate'
import { NewsFilters } from './home-filters'
import { NewsArticleHomePreview } from './news-article-home-preview'

type Props = {
  filters?: NewsFilters
  offset?: number
  initialSize?: number
  pageSize?: number
  exclude?: string[]
  pagination?: boolean
  archiveLink?: boolean
  onCountryChange?: (country: string) => void
  onChannelChange?: (channel: string) => void
  css?: CSS
}

export const HomeNewsGraduation = ({
  filters = {},
  offset = 0,
  exclude,
  onCountryChange,
  onChannelChange,
  css
}: Omit<Props, 'pageSize' | 'pagination'>) => {
  const { news, isLoading } = useNewsInfinite({
    ...filters,
    limit: 1,
    exclude,
    offset
  })
  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)
  if (isLoading)
    return (
      <Box css={css}>
        <Spinner
          css={{
            mt: '$27',
            '@sm': {
              mt: '$24'
            }
          }}
        />
      </Box>
    )
  if (!news.length) return null
  return (
    <Box css={css}>
      {isLg ? (
        <ContainerPadding>
          <Container>
            <NewsArticleHomePreview
              article={news[0]}
              variant="feed"
              isLarge
              onCountryChange={onCountryChange}
              onChannelChange={onChannelChange}
              isCountryHidden={filters?.country === news[0]?.location[0]}
              isChannelHidden={filters?.channel === news[0]?.channels[0]}
            />
          </Container>
        </ContainerPadding>
      ) : (
        <NewsArticleCard
          article={news[0]}
          css={{ border: 'none', boxShadow: 'none' }}
          onCountryChange={onCountryChange}
          onChannelChange={onChannelChange}
          isCountryHidden={filters?.country === news[0]?.location[0]}
          isChannelHidden={filters?.channel === news[0]?.channels[0]}
        />
      )}
    </Box>
  )
}

const HomeNewsBlock = ({
  filters = {},
  offset = 0,
  pageSize = SEARCH_PAGE_LIMIT,
  pagination = false,
  archiveLink = false,
  initialSize,
  exclude,
  onCountryChange,
  onChannelChange,
  css
}: Props) => {
  const { news, page, setPage, isLoading, next } = useNewsInfinite({
    ...filters,
    initialSize,
    exclude,
    limit: pageSize,
    offset
  })
  const [isShowNextButton, setIsShowNextButton] = useState(false)
  const { t } = useTranslate()

  useEffect(() => {
    if (!isLoading) {
      setIsShowNextButton(next)
    }
  }, [isLoading, next])

  return (
    <Box css={css}>
      <Box
        css={{
          flexDirection: 'row',
          d: 'flex',
          flexWrap: 'wrap',
          my: -16,
          '@lg': { mx: -20, my: -20 }
        }}
      >
        {news?.map((article: NewsArticle) => (
          <Box
            key={article.id}
            id={article.id}
            css={{
              d: 'flex',
              flexBasis: '100%',
              py: 16,
              '@lg': { flexBasis: '50%', p: 20 }
            }}
          >
            <NewsArticleCard
              key={article.id}
              article={article}
              onCountryChange={onCountryChange}
              onChannelChange={onChannelChange}
              isCountryHidden={filters?.country === article.location[0]}
              isChannelHidden={filters?.channel === news[0]?.channels[0]}
              css={{ height: '100%' }}
            />
          </Box>
        ))}
      </Box>
      {isLoading && (
        <Spinner
          css={{
            mt: '$27',
            '@sm': {
              mt: '$24'
            }
          }}
        />
      )}

      {isShowNextButton && pagination ? (
        <Box
          css={{
            d: 'flex',
            justifyContent: 'center',
            mt: '$27',
            mb: '$12',
            '@sm': {
              mt: '$24',
              mb: '$6'
            }
          }}
        >
          <Button
            disabled={isLoading}
            variant="secondary"
            onClick={() => setPage(page + 1)}
          >
            {t('field_load_more', [`${pageSize}`])}
          </Button>
        </Box>
      ) : null}
      {archiveLink && (
        <Body3 css={{ textAlign: 'center', mt: '$8' }}>
          Looking for a specific news? Go to{' '}
          <TextLink
            as={Link}
            onClick={() => {
              document.documentElement.scrollTo(0, 0)
            }}
            to={`/${searchPageUrl}`}
            css={{
              color: 'inherit',
              borderBottom: '1px solid black',
              '&:hover': { color: 'inherit' }
            }}
          >
            News Archive
          </TextLink>
        </Body3>
      )}
    </Box>
  )
}

export default HomeNewsBlock
