import React from 'react'
import {
  Body5,
  Body7,
  Heading4,
  Box,
  LazyImage,
  Video,
  Space
} from '@sefar/design-system'
import { Link, useLocation } from 'react-router-dom'
// @ts-ignore
import Clamp from 'react-multiline-clamp'
import { useMedia } from 'react-use'
import { ChannelBadge } from './channel-badge'
import { LocationBadge } from './location-badge'
import { ArticleInfo } from '../article/article-info'
import { ChannelsEnum, MEDIA_TYPES, NewsArticle } from '../../api'
import { BREAKPOINTS_QUERIES, styled } from '../../../../../../stitches.config'
import { CSS } from '@stitches/react'

export const ArticleCard = styled('article', {
  width: '100%',
  color: '$neutral',
  border: '1px $neutralLighten90 solid',
  borderRadius: '$4',
  boxShadow: '$card',
  '&:hover': {
    boxShadow: '$cardHover'
  },
  overflow: 'hidden'
})

export const ArticleContent = styled(Box, {
  py: '$6',
  px: '$8',
  '@sm': {
    pt: '$4',
    px: '$4'
  }
})

export const ArticleLink = styled(Link, {
  all: 'unset',
  d: 'block',
  cursor: 'pointer'
})

export function NewsArticleCard({
  article,
  onCountryChange,
  onChannelChange,
  isCountryHidden = false,
  isChannelHidden = false,
  css
}: {
  article: NewsArticle
  onCountryChange?: (country: string) => void
  onChannelChange?: (channel: string) => void
  isCountryHidden?: boolean
  isChannelHidden?: boolean
  css?: CSS
}) {
  const location = useLocation()
  const isMd = useMedia(BREAKPOINTS_QUERIES.md)

  return (
    <ArticleCard css={css}>
      <ArticleLink
        to={`/news/${article.id}`}
        state={{ from: location.pathname }}
        css={{ d: 'flex', flexDirection: 'column', height: '100%' }}
      >
        {article.mediaType && MEDIA_TYPES.video.includes(article.mediaType) ? (
          <Video src={article.media?.url} />
        ) : (
          <LazyImage aspectRatio="2 / 1" src={article.media?.url}></LazyImage>
        )}
        <ArticleContent>
          <Box css={{ d: 'flex', alignItems: 'center', gap: '14px', mb: '$4' }}>
            <Body7>{article.date}</Body7>
            {!isCountryHidden && (
              <LocationBadge
                locationName={article.location[0]}
                onClick={() => onCountryChange?.(article.location[0])}
              />
            )}
            {!isChannelHidden && (
              <ChannelBadge
                channelKey={article.channels[0] as ChannelsEnum}
                onClick={() => onChannelChange?.(article.channels[0])}
              />
            )}
          </Box>
          <Heading4 css={{ mb: '$2' }}>{article.title}</Heading4>
          <Body5>
            <Clamp lines={isMd ? 2 : 3} maxLines={Number.MAX_SAFE_INTEGER}>
              <Box dangerouslySetInnerHTML={{ __html: article.lead }} />
            </Clamp>
          </Body5>

          <Space mt="MD">
            <ArticleInfo article={article} />
          </Space>
        </ArticleContent>
      </ArticleLink>
    </ArticleCard>
  )
}
