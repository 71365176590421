import {
  Add16,
  Body5,
  Box,
  ChevronUpIcon16,
  Container,
  ContainerPadding,
  DropdownMenu,
  DropdownMenuItem,
  Space,
  Tabs,
  TabsList,
  TabsTrigger
} from '@sefar/design-system'
import { styled } from '@stitches/react'
import { MouseEvent, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useMedia, useUpdateEffect } from 'react-use'
import { BREAKPOINTS_QUERIES } from '../../../../stitches.config'
import {
  ALL_COUNTRIES_REGION,
  ChannelTypeEnum,
  ChannelsEnum,
  useCountries,
  useMe,
  useNewsCategories
} from '../../api'
import { localStorageKeys } from '../../constants'
import { useTranslate } from '../../hooks/useTranslate'

const StyledChevronUpIcon16 = styled(ChevronUpIcon16, {})

export type NewsFilters = {
  category?: string[]
  country?: string
  channel?: string
  categoryExclude?: string[]
}

type Props = {
  onFiltersChange: (filters: NewsFilters) => void
  onFiltersInit?: (filters: NewsFilters) => void
  filters: NewsFilters
}

const HomeFilters = ({ onFiltersChange, filters, onFiltersInit }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { countries } = useCountries()
  const { categories } = useNewsCategories()
  const ALL_CATEGORIES: [string, string] = ['', 'All Categories']
  const { me } = useMe()

  useEffect(() => {
    const initCountry =
      localStorage.getItem(localStorageKeys.NEWS_CHOSEN_COUNTRY) || ''
    const currentChannel = searchParams.get('channel')
    const currentCategories = searchParams.getAll('category')
    const initialFilters = {
      ...filters
    }
    initialFilters.country = initCountry

    if (currentChannel) {
      initialFilters.channel = currentChannel
    } else {
      initialFilters.channel = ChannelsEnum.all
    }
    if (currentCategories?.length) {
      initialFilters.category = currentCategories
    }
    if (onFiltersInit) {
      onFiltersInit(initialFilters)
    }
  }, [])

  useUpdateEffect(() => {
    const currentRegion = filters.channel
    if (currentRegion === ChannelTypeEnum.all) {
      searchParams.delete('region')
    }
  }, [searchParams, filters.channel])

  const { t } = useTranslate()
  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)

  return (
    <Box
      css={{
        width: '100%',
        backgroundColor: '$white',
        position: 'sticky',
        top: 64,
        borderTop: '1px solid #EFEFEF',
        borderBottom: '1px solid #EFEFEF',
        zIndex: 10,
        h: 141,
        '@md': {
          h: 61
        }
      }}
    >
      <ContainerPadding>
        <Container size="medium">
          <Tabs
            value={filters.channel}
            onValueChange={(value) => {
              if (Object.values(countries || {})?.includes(value)) {
                onFiltersChange({ ...filters, country: value })
                localStorage.setItem(
                  localStorageKeys.NEWS_CHOSEN_COUNTRY,
                  value
                )
              }
              onFiltersChange({ ...filters, channel: value })
              if (value) {
                searchParams.set('channel', value)
                if (value === ChannelsEnum.company_focus) {
                  onFiltersChange({ channel: value })
                }
              } else {
                onFiltersChange({
                  ...filters,
                  channel: value,
                  category: []
                })
                searchParams.delete('channel')
                searchParams.delete('category')
              }
              setSearchParams(searchParams)
            }}
          >
            <TabsList
              aria-label="News target area"
              css={{
                d: 'flex',
                justifyContent: 'center',
                '@md': {
                  flexWrap: 'nowrap'
                },
                flexWrap: 'wrap'
              }}
            >
              <TabsTrigger
                css={{
                  height: 44,
                  '&::after': {
                    bottom: 0
                  },
                  mr: '$3',
                  fontSize: 12,
                  '@md': {
                    fontSize: '$button2Xl'
                  }
                }}
                data-cy="NewsTabAll"
                value={ChannelTypeEnum.all}
              >
                {t('field_all')}
              </TabsTrigger>
              <TabsTrigger
                css={{
                  height: 44,
                  '&::after': {
                    bottom: 0
                  },
                  mr: '$3',
                  fontSize: 12,
                  '@md': {
                    fontSize: '$button2Xl'
                  }
                }}
                data-cy="NewsTabGlobal"
                value={ChannelsEnum.company_focus}
              >
                {t('field_company_focus_caption')}
              </TabsTrigger>
              <TabsTrigger
                css={{
                  height: 44,
                  '&::after': {
                    bottom: 0
                  },
                  mr: '$3',
                  fontSize: 12,
                  '@md': {
                    fontSize: '$button2Xl'
                  }
                }}
                data-cy="NewsTabGlobal"
                value={ChannelsEnum.stories}
              >
                {t('field_stories_caption')}
              </TabsTrigger>
              <TabsTrigger
                css={{
                  height: 44,
                  '&::after': {
                    bottom: 0
                  },
                  mr: '$3',
                  fontSize: 12,
                  '@md': {
                    fontSize: '$button2Xl'
                  }
                }}
                data-cy="NewsTabGlobal"
                value={ChannelsEnum.announcements}
              >
                {t('field_announcements_caption')}
              </TabsTrigger>
              <DropdownMenu
                disabled={filters.channel === ChannelsEnum.company_focus}
                trigger={(isOpen) => (
                  <Space
                    onClick={(event) => event.stopPropagation()}
                    css={{
                      d: 'flex',
                      flexDirection: 'row',
                      '&:hover > div': {
                        color: '$neutral',
                        backgroundColor: '$primaryBlueLighten95'
                      },
                      ml: 'auto',
                      cursor: 'pointer',
                      '@lg': {
                        ml: 0
                      },
                      flexBasis: '50%',
                      justifyContent: 'center',
                      '@md': {
                        flexBasis: 'auto',
                        justifyContent: 'flex-start'
                      }
                    }}
                  >
                    <Box
                      css={{
                        d: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        '@lg': {
                          p: '$3 $3',
                          ml: '$2'
                        },
                        height: 60,
                        position: 'relative'
                      }}
                    >
                      <Body5
                        fontWeight="bold"
                        css={{
                          color: '$neutralLighten30',
                          fontSize: 12,
                          '@md': {
                            fontSize: '$button2Xl'
                          }
                        }}
                      >
                        {isLg
                          ? filters.country || t('field_select_country')
                          : t('field_country')}
                      </Body5>
                    </Box>
                    <Box css={{ height: 60, d: 'flex', alignItems: 'center' }}>
                      <Box
                        css={{
                          px: '$1',
                          '@lg': {
                            borderLeft: '1px solid $neutralLighten80',
                            px: '$4'
                          },
                          height: 44,
                          d: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer'
                        }}
                      >
                        <StyledChevronUpIcon16
                          onClick={(event: MouseEvent<HTMLOrSVGElement>) =>
                            event.stopPropagation()
                          }
                          css={{
                            transform: isOpen
                              ? 'translateY(9%)'
                              : 'rotate(180deg)'
                          }}
                        />
                      </Box>
                    </Box>
                  </Space>
                )}
                content={() =>
                  countries &&
                  [ALL_COUNTRIES_REGION, ...countries].map(
                    (country: string, index: number) => (
                      <DropdownMenuItem
                        key={country}
                        data-cy={`NewsTabOption-${index}`}
                        onClick={() => {
                          onFiltersChange({
                            ...filters,
                            country
                          })
                          localStorage.setItem(
                            localStorageKeys.NEWS_CHOSEN_COUNTRY,
                            country
                          )
                          searchParams.set('country', country)
                          setSearchParams(searchParams)
                        }}
                        active={filters.country === country}
                      >
                        {country}
                      </DropdownMenuItem>
                    )
                  )
                }
                contentProps={{
                  align: 'center',
                  side: 'bottom',
                  css: { maxHeight: 200, overflowY: 'auto' }
                }}
              />
              <DropdownMenu
                disabled={filters.channel === ChannelsEnum.company_focus}
                trigger={(isOpen) => (
                  <Space
                    onClick={(event) => event.stopPropagation()}
                    css={{
                      d: 'flex',
                      flexDirection: 'row',
                      '&:hover > div': {
                        color: '$neutral',
                        backgroundColor: '$primaryBlueLighten95'
                      },
                      ml: 'auto',
                      cursor: 'pointer',
                      '@lg': {
                        ml: 0
                      },
                      flexBasis: '50%',
                      justifyContent: 'center',
                      '@md': {
                        flexBasis: 'auto',
                        justifyContent: 'flex-start'
                      }
                    }}
                  >
                    <Box
                      css={{
                        d: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        '@lg': {
                          p: '$3 $3',
                          ml: '$2'
                        },
                        height: 60,
                        position: 'relative'
                      }}
                    >
                      <Body5
                        fontWeight="bold"
                        css={{
                          color: '$neutralLighten30',
                          fontSize: 12,
                          '@md': {
                            fontSize: '$button2Xl'
                          }
                        }}
                      >
                        {t('field_news_categories_text')}
                      </Body5>
                    </Box>
                    <Box css={{ height: 60, d: 'flex', alignItems: 'center' }}>
                      <Box
                        css={{
                          px: '$1',
                          '@lg': {
                            borderLeft: '1px solid $neutralLighten80',
                            px: '$4'
                          },
                          height: 44,
                          d: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer'
                        }}
                      >
                        <StyledChevronUpIcon16
                          onClick={(event: MouseEvent<HTMLOrSVGElement>) =>
                            event.stopPropagation()
                          }
                          css={{
                            transform: isOpen
                              ? 'translateY(9%)'
                              : 'rotate(180deg)'
                          }}
                        />
                      </Box>
                    </Box>
                  </Space>
                )}
                content={() =>
                  categories &&
                  [ALL_CATEGORIES, ...Object.entries(categories)].map(
                    ([category, label]: [string, any]) => (
                      <DropdownMenuItem
                        key={category}
                        onClick={() => {
                          const baseFilters = { ...filters }

                          let newCategories: string[]
                          if (
                            filters.category?.length &&
                            filters.category.includes(category)
                          ) {
                            newCategories = filters.category.filter(
                              (selectedCategory) =>
                                category !== selectedCategory
                            )
                          } else {
                            newCategories = filters.category?.length
                              ? [...filters.category, category]
                              : [category]
                          }
                          searchParams.delete('category')
                          if (category !== ALL_CATEGORIES[0]) {
                            for (const category of newCategories) {
                              searchParams.append('category', category)
                            }
                            setSearchParams(searchParams)
                            onFiltersChange({
                              ...baseFilters,
                              category: newCategories
                            })
                          } else {
                            setSearchParams(searchParams)
                            onFiltersChange({
                              ...baseFilters,
                              category: []
                            })
                          }
                        }}
                        active={filters.category?.includes(category)}
                      >
                        {label}
                      </DropdownMenuItem>
                    )
                  )
                }
                contentProps={{
                  align: 'center',
                  side: 'bottom',
                  css: { maxHeight: 200, overflowY: 'auto' }
                }}
              />
            </TabsList>
          </Tabs>
        </Container>
      </ContainerPadding>
    </Box>
  )
}

export default HomeFilters
