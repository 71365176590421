import React, { ComponentProps } from 'react'
import { styled } from '../../../stitches.config'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import { Body7 } from '../typography'

const StyledTabs = styled(TabsPrimitive.Root, {
  transition: '$default',
  display: 'flex',
  flexDirection: 'column'
})

const StyledList = styled(TabsPrimitive.List, {
  transition: '$default',
  flexShrink: 0,
  display: 'flex',
  justifyContent: 'space-between',
  '@md': {
    justifyContent: 'flex-start'
  }
})

const StyledTrigger = styled(TabsPrimitive.Trigger, {
  all: 'unset',
  whiteSpace: 'nowrap',
  transition: '$default',
  fontFamily: 'inherit',
  padding: '$2 $1',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '$button2Xl',
  lineHeight: '$12',
  fontWeight: '$bold',
  color: '$neutralLighten30',
  position: 'relative',
  marginBottom: '$3',
  borderRadius: '$2',
  cursor: 'pointer',
  mr: 0,
  '@md': {
    padding: '$2 $3',
    mr: '$4'
  },
  '&::after': {
    display: 'block',
    content: '',
    position: 'absolute',
    borderBottom: '1px transparent solid',
    width: '100%',
    bottom: -13
  },
  '&:hover, &.__hover': {
    color: '$neutral',
    backgroundColor: '$primaryBlueLighten95',
    '& .tab-badge': {
      backgroundColor: '$primaryBlue'
    }
  },
  '&[data-state="active"], &:focus': {
    color: '$neutral',
    '&::after': {
      borderBottom: '3px $primaryBlueLighten50 solid'
    },
    '& .tab-badge': {
      backgroundColor: '$primaryBlue'
    }
  },
  '&:disabled, [data-disabled]': {
    color: '$neutralLighten60',
    cursor: 'default',
    pointerEvents: 'none',
    '& .tab-badge': {
      color: '$neutralLighten60',
      backgroundColor: '$neutralLighten93'
    }
  }
})

const StyledTabsBadge = styled(Body7, {
  transition: '$default',
  ml: '$1',
  '@md': {
    ml: '$2'
  },
  height: '100%',
  d: 'flex',
  alignItems: 'center',
  fontWeight: '$normal',
  color: '$white',
  backgroundColor: '$neutralLighten30',
  px: '$2',
  borderRadius: 10
})

export const Tabs = StyledTabs
export const TabsList = StyledList
export const TabsTrigger = StyledTrigger
export const TabsContent = styled(TabsPrimitive.Content)
export function TabsBadge(props: ComponentProps<typeof StyledTabsBadge>) {
  return <StyledTabsBadge className="tab-badge" {...props} />
}
