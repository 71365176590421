import React, { useEffect } from 'react'
import { mergeAttributes, Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import { NodeViewWrapper } from '@tiptap/react'

import {
  Download16,
  TextLink,
  Box,
  Body5,
  Document24,
  Body6,
  LazyImage,
  Button,
  Spinner,
  ViewIcon16
} from '@sefar/design-system'
import { useFilePreview } from '../../../api'

const FileInContent = ({ ...props }) => {
  const { data, isLoading } = useFilePreview(props.node.attrs['preview-id'])
  const previewLink = data?.embeds?.document_viewer_with_download?.url
  const downloadLink = data?._links?.download
  return props.node.attrs['preview-id'] ? (
    <NodeViewWrapper
      as={Box}
      css={{ mb: '$4', color: 'inherit' }}
      contentEditable="false"
      draggable="true"
    >
      <Box
        css={{
          height: 103,
          boxShadow: '$card',
          pl: '$4',
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          borderRadius: '$4',
          overflow: 'hidden',
          '&:hover > .actions-menu': {
            opacity: 100
          }
        }}
      >
        {isLoading ? (
          <Box
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              flexGrow: 1
            }}
          >
            <Box css={{ display: 'flex', alignItems: 'center' }}>
              <Spinner size="small" />
              <Body5 fontWeight="bold" css={{ color: '$neutral', ml: '$2' }}>
                Creating preview...
              </Body5>
            </Box>
          </Box>
        ) : (
          <Box
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              flexGrow: 1
            }}
          >
            <Box
              css={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <Body5 fontWeight="bold" css={{ color: '$neutral', mb: '$2' }}>
                {data?.filename}
              </Body5>
              <Box
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  '& > svg': {
                    stroke: '$neutralLighten30',
                    width: 11,
                    height: 13
                  }
                }}
              >
                <Document24 />
                <Body6
                  css={{
                    ml: '$2',
                    textTransform: 'uppercase',
                    color: '$neutralLighten30',
                    fontWeight: 400
                  }}
                >
                  {data?.file_properties?.format_type} -{' '}
                  {data?.file_properties?.size_in_kbytes}KB
                </Body6>
              </Box>
            </Box>
            <Box>
              <LazyImage
                src={data?.thumbnails?.['300px']?.url}
                aspectRatio="16 / 9"
                css={{ height: 103, margin: '0 !important' }}
              />
            </Box>
          </Box>
        )}
        <Box
          className="actions-menu"
          css={{
            backgroundColor: 'rgba(0, 25, 42, 0.4)',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            p: '$3',
            opacity: 0
          }}
        >
          <a href={downloadLink} target="_blank" rel="noreferrer">
            <Button variant="secondary" icon>
              <Download16 />
            </Button>
          </a>
          {previewLink && (
            <a href={previewLink} target="_blank" rel="noreferrer">
              <Button variant="secondary" icon css={{ ml: '$2' }}>
                <ViewIcon16 />
              </Button>
            </a>
          )}
        </Box>
      </Box>
    </NodeViewWrapper>
  ) : (
    <NodeViewWrapper
      as={TextLink}
      href={props.node.attrs.src}
      download
      target="_blank"
      css={{ mb: '$4' }}
      contentEditable="false"
      draggable="true"
    >
      <Box as="span" className="icon icon-left">
        <Download16 />
      </Box>
      {props.node.attrs.name}
    </NodeViewWrapper>
  )
}

export default Node.create({
  name: 'fileInContent',

  group: 'block',

  content: 'inline*',

  atom: true,

  editable: false,

  parseHTML() {
    return [
      {
        tag: 'fileInContent'
      }
    ]
  },

  addAttributes() {
    return {
      src: {
        default: null
      },
      name: {
        default: null
      },
      'preview-id': {
        default: null
      }
    }
  },

  renderHTML({ HTMLAttributes }) {
    return ['fileInContent', mergeAttributes(HTMLAttributes), 0]
  },

  addNodeView() {
    return ReactNodeViewRenderer(FileInContent)
  }
})
