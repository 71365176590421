import { Link, useNavigate } from 'react-router-dom'
import { useMedia } from 'react-use'
import {
  ArrowRightIcon16,
  Body4,
  Body5,
  Box,
  Container,
  Heading3,
  Heading4,
  LazyImage,
  Spinner
} from '@sefar/design-system'
import { useNewsInfinite, NewsArticle, ChannelsEnum } from '../../api'
import { newsPageUrl } from '../../app'
import { formatArticleDate } from '../../components'
import { BREAKPOINTS_QUERIES } from '../../../../../../stitches.config'
import { NewsFilters } from './home-filters'
import { useTranslate } from '../../hooks/useTranslate'

type Props = {
  article: NewsArticle
}

const HomeCompanyFocusCard = ({ article }: Props) => {
  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)
  const categories = article.categories.length
    ? article.categories.map((cat) =>
        typeof cat !== 'string' ? cat?.value : cat
      )
    : []
  return (
    <Link
      to={`/${newsPageUrl}/${article.id}`}
      style={{
        textDecoration: 'none',
        width: isLg ? 'calc(50% - 20px)' : '100%'
      }}
    >
      <Box
        css={{
          d: 'flex',
          alignItems: 'center',
          gap: '1rem',
          cursor: 'pointer',
          '&:hover': { opacity: 0.7 }
        }}
      >
        <Box
          css={{
            w: isLg ? 140 : 100,
            h: 100,
            flexShrink: 0,
            borderRadius: '$4',
            overflow: 'hidden'
          }}
        >
          <LazyImage
            src={article.previewMedia}
            aspectRatio={isLg ? '7 / 5' : '1 / 1'}
          />
        </Box>
        <Box>
          <Box
            css={{
              color: '$neutralLighten30',
              fontSize: isLg ? '$body6' : '$body7',
              lineHeight: '18px'
            }}
          >
            {categories.length ? categories.join(', ') : ''}
            {categories.length ? ' · ' : ''}
            {formatArticleDate(article.date)}
          </Box>
          <Body5
            css={{
              color: '$black',
              mt: '2px'
            }}
            fontWeight="bold"
          >
            {article.title}
          </Body5>
        </Box>
      </Box>
    </Link>
  )
}

type CompanyFocusProps = {
  filters?: NewsFilters
  offset?: number
  initialSize?: number
  pageSize?: number
  exclude?: string[]
  pagination?: boolean
  archiveLink?: boolean
}

export const HomeCompanyFocus = ({
  offset = 0,
  exclude
}: CompanyFocusProps) => {
  const { news, isLoading } = useNewsInfinite({
    channel: ChannelsEnum.company_focus,
    limit: 6,
    exclude,
    offset
  })
  const { t } = useTranslate()
  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)
  const TitleComponent = isLg ? Heading3 : Heading4

  return (
    <Box
      css={{
        background: '$primaryBlueLighten95',
        width: '100%',
        mb: '$16',
        py: isLg ? '$10' : '$15',
        px: isLg ? undefined : '$4'
      }}
    >
      <Container>
        <Box
          css={{
            d: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <TitleComponent css={{ mb: '$8' }}>
            {t('field_company_focus_caption')}
          </TitleComponent>
        </Box>
        <Box
          css={{
            d: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            columnGap: '$10',
            rowGap: '$5'
          }}
        >
          {isLoading ? (
            <Spinner />
          ) : (
            news.map((article: NewsArticle) => (
              <HomeCompanyFocusCard key={article.id} article={article} />
            ))
          )}
        </Box>
      </Container>
    </Box>
  )
}

export default HomeCompanyFocus
