import type { MouseEvent } from 'react'
import { Body5, Box } from '@sefar/design-system'
import type * as Stitches from '@stitches/react'
import { ChannelsEnum } from '../../api'

const channelKeyToLabel: Record<ChannelsEnum, string> = {
  [ChannelsEnum.stories]: 'Story',
  [ChannelsEnum.announcements]: 'Announcements',
  [ChannelsEnum.company_focus]: 'Company focus',
  [ChannelsEnum.all]: ''
}

export const ChannelBadge = ({
  channelKey,
  onClick,
  css
}: {
  channelKey?: ChannelsEnum
  onClick?: () => void
  css?: Stitches.CSS
}) => {
  if (!channelKey) {
    return null
  }

  const onClickHandle = (e: MouseEvent) => {
    e.preventDefault()
    onClick?.()
  }

  return (
    <Box
      css={{
        px: '3px',
        py: '1px',
        borderRadius: '$2',
        border: '1px solid $neutralLighten80',
        w: 'fit-content',
        h: 'fit-content',
        opacity: 1,
        '&:hover': {
          background: '#CAC9C9',
          border: '1px solid $neutralLighten80'
        },
        ...css
      }}
      onClick={onClickHandle}
    >
      <Body5
        fontWeight="bold"
        css={{
          color: 'rgb(0, 0, 0, 0.8)',
          fontSize: '10px',
          lineHeight: '15px'
        }}
      >
        {channelKeyToLabel[channelKey] || channelKey || ''}
      </Body5>
    </Box>
  )
}
