import useSWR from 'swr'
import useSWRImmutable from 'swr/immutable'
import useSWRInfinite from 'swr/infinite'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'

import { useConfigStore } from '../state/config'
import { transferUser, User } from './user'
import { API_DOMAIN, fetcherFullRequest, init, Likes } from './common'
import {
  convertImageDataToRelationship,
  UploadProfiles,
  MEDIA_TYPES,
  getFileType,
  ImageToAttachToArticle
} from './media'
import { formatArticleDate } from '../components'
import { UND_PHP } from './language'
import {
  FileMeta,
  getNewlyAddedFileMetaInitialState
} from '../pages/news-details/helpers'
import { formatFileName } from '../utils/formatFileName'
import { COMMENT_TYPE } from './comment'
import { useEffect, useMemo } from 'react'
import { SEARCH_PAGE_LIMIT } from './search'
import { SPACE_ARTICLE_TYPE, SUBSPACE_TYPE } from './space'

export type MainMedia = {
  meta?: FileMeta
  file?: File
  attachmentInfo?: ImageToAttachToArticle
  url?: string
}

export const NEWS_ARTICLE_TYPE = 'node--news'

export enum ChannelTypeEnum {
  all = '',
  global = 'Global',
  counties = 'counties'
}

export const GLOBAL_LOCATION = 'Global'

export enum ChannelsEnum {
  all = '',
  company_focus = 'company_focus',
  announcements = 'announcements',
  stories = 'stories'
}

export const ALL_COUNTRIES_REGION = 'All countries'

export enum ReviewStateEnum {
  'Not reviewed' = 'Not reviewed',
  'Reviewed' = 'Reviewed',
  'On review' = 'On review',
  'Review with feedback' = 'Review with feedback'
}

export type NewsArticle = {
  id: string
  drupalInternalNid: string
  date: string
  shortDate: string
  title: string
  langOrigin: string
  lang: string
  lead: string
  text: string
  commentCount: number
  media: MainMedia | undefined
  status: boolean
  likesDisabled: boolean
  publishDate: string
  unpublishDate: string
  viewers: number
  author: User | undefined
  editor: User | undefined
  reviewer: User | undefined
  mediaType: string | undefined
  previewMedia: string | undefined
  location: string[]
  categories: string[] | { value: string }[]
  channels: string[]
  reviewState?: ReviewStateEnum
  reviewFeedback: string
  reviewNote: string
  likes: Likes
  isMustRead?: boolean
  mustReadResetDate?: string
}

export type Relationship = {
  field_media: {
    data: {
      type: string
      id: string
      meta: {
        drupal_internal__target_id: string
      }
    }
  }
}

export type Attributes = Record<
  string,
  | string
  | boolean
  | string[]
  | Record<string, unknown>
  | Record<string, string | Record<string, string | Record<string, string>>>
>

export function useNewsInfinite<T = NewsArticle[]>({
  region,
  country,
  channel = '',
  limit = SEARCH_PAGE_LIMIT,
  initialSize,
  category,
  categoryExclude,
  offset = 0,
  exclude
}: {
  region?: string
  country?: string
  channel?: string
  limit?: number
  initialSize?: number
  category?: string[]
  categoryExclude?: string[]
  offset?: number
  exclude?: string[]
}) {
  const { contentLang } = useConfigStore.getState()
  const langQuery = contentLang === 'en' ? '' : `/${contentLang}`
  const countryFilter =
    country === ALL_COUNTRIES_REGION
      ? ''
      : country
        ? `&filter[field_location][operator]==&filter[field_location][value]=${country}`
        : ''

  let categoryFilter = ''
  if (category?.length && !categoryExclude?.length) {
    categoryFilter = category?.length
      ? category.reduce(
          (filter, category) =>
            (filter += `&filter[field_news_category][operator]=IN&filter[field_news_category][value][]=${category}`),
          ''
        )
      : ''
  } else if (categoryExclude?.length) {
    categoryFilter = categoryExclude.reduce(
      (filter, category) =>
        (filter += `&filter[field_news_category][operator]=<>&filter[field_news_category][value][]=${category}`),
      categoryFilter
    )
  }
  const excludeFilter = exclude?.length
    ? exclude.reduce(
        (filter, NidtoExclude) => (filter += `&exclude[]=${NidtoExclude}`),
        ''
      )
    : ''
  let channelFilter = ''
  if (channel) {
    channelFilter = `&filter[field_news_channel]=${channel}`
  }
  const { data, error, setSize, size } = useSWRInfinite(
    (page: number, previousPageData: any) => {
      if (previousPageData && !previousPageData?.links?.next) return null // reached the end
      const pageLimit = page === 0 && initialSize ? initialSize : limit
      const limitation = limit ? `&page[limit]=${pageLimit}` : ''
      const pageOffset =
        page > 0 && initialSize
          ? (page - 1) * pageLimit + offset + initialSize
          : page * pageLimit + offset

      const offsetQuery =
        typeof page === 'number' && pageLimit
          ? `&page[offset]=${pageOffset}`
          : ''
      return [
        `${API_DOMAIN}${langQuery}/news?filter[status][value]=1&sort=-published_at&filter[field_location][path]=field_location${countryFilter}${categoryFilter}${limitation}${offsetQuery}${excludeFilter}${channelFilter}`,
        init
      ]
    },
    fetcherFullRequest,
    {
      persistSize: true,
      revalidateFirstPage: false
    }
  )
  const newsArticles = useMemo(
    () =>
      data?.flatMap((page) => transferNewsArticles(page?.data, page.included)),
    [data]
  )
  return {
    news: newsArticles?.length ? newsArticles : [],
    page: size,
    setPage: setSize,
    next: !!data?.[size - 1]?.links?.next,
    isLoading: !error && !data?.[size - 1],
    isError: error
  }
}

export function useSpotlightNews() {
  const { contentLang } = useConfigStore.getState()
  const langQuery = contentLang === 'en' ? '' : `/${contentLang}`

  const { data, error } = useSWR(
    [`${API_DOMAIN}${langQuery}/news/spotlight`, init],
    fetcherFullRequest
  )

  return {
    spotlightArticles: data
      ? transferNewsArticles(data?.data, data.included)
      : [],
    error
  }
}

export function useNews<T = NewsArticle[]>(
  region?: string,
  selectedCountry?: string,
  limit?: number,
  page?: number
) {
  const { contentLang } = useConfigStore.getState()
  const langQuery = contentLang === 'en' ? '' : `/${contentLang}`
  const limitation = limit ? `&page[limit]=${limit}` : ''
  const offset = page && limit ? `&page[offset]=${+page * limit}` : ''
  const regionFilter =
    selectedCountry === ALL_COUNTRIES_REGION
      ? !region
        ? '' // all countries and global
        : region === ChannelTypeEnum.global
          ? `&filter[field_location][operator]==&filter[field_location][value]=Global` // global only
          : '&filter[field_location][operator]=<>&filter[field_location][value]=Global' // all countries without global
      : !region
        ? `&filter[field_location][operator]=IN&filter[field_location][value][1]=Global&filter[field_location][value][2]=${selectedCountry}` // one country only and global
        : `&filter[field_location][operator]==&filter[field_location][value]=${region}` // one country only

  const { data, error } = useSWR(
    [
      `${API_DOMAIN}${langQuery}/news?filter[status][value]=1&sort=-published_at${regionFilter}${limitation}${offset}`,
      init
    ],
    fetcherFullRequest
  )

  return {
    news: data ? transferNewsArticles(data?.data, data.included) : data,
    next: data && !!data?.links?.next,
    ids: data
      ? transferNewsArticles(data?.data, data.included)
          ?.map(
            ({ id, title, commentCount, likes: { likes, dislikes }, lead }) =>
              `${id}-${title}-${commentCount}-${likes}-${dislikes}-${lead}`
          )
          ?.toString()
      : data,
    isLoading: !error && !data,
    isError: error
  }
}

export function useNewsArticle<T = NewsArticle>(
  id: string | undefined,
  lang: string,
  transform?: (data: any[]) => T
) {
  const { me } = useConfigStore.getState()
  const langQuery = lang === 'en' ? '' : `/${lang}`
  const { data, error, mutate } = useSWR(
    id
      ? [
          `${API_DOMAIN}${langQuery}/jsonapi/node/news/${id}?include=field_media,field_news_author.user_picture,uid.user_picture`,
          init
        ]
      : undefined,
    fetcherFullRequest
  )

  const updateViewers = async (field_computed_viewers: number) => {
    const updatedViewersResponse = await updateNewsViewers(data.data.id)
    if (updatedViewersResponse?.Status === 'View tracked.') {
      mutate(
        set(
          cloneDeep(data),
          'data.attributes.field_computed_viewers',
          field_computed_viewers
        )
      )
    }
  }

  useEffect(() => {
    const currentViewersNumber = data?.data?.attributes?.field_computed_viewers
    if (currentViewersNumber >= 0 && me) {
      updateViewers(currentViewersNumber + 1)
    }
  }, [me, data?.data?.attributes?.field_computed_viewers])

  return {
    article: data
      ? (transform && transform(data.data)) ||
        transferNewsArticle(data.data, data?.included)
      : data,
    isLoading: !error && !data,
    isError: error,
    mutate
  }
}

export function useMustReadNews<T = NewsArticle[]>(
  userInternalUid?: string,
  limit?: number,
  page?: number
) {
  const { contentLang } = useConfigStore.getState()
  const langQuery = contentLang === 'en' ? '' : `/${contentLang}`
  const limitation = limit ? `&page[limit]=${limit}` : ''
  const offset = page && limit ? `&page[offset]=${+page * limit}` : ''

  const { data, error } = useSWR(
    [
      `${API_DOMAIN}${langQuery}/api/search?include=field_media,field_news_author.user_picture&filter[type]=news&filter[status][value]=1&filter[field_news_must_read]=1&filter[viewers][condition][path]=field_news_viewers&filter[viewers][condition][operator]=<>&filter[viewers][condition][value]=${userInternalUid}&sort=-field_news_must_read,-published_at${limitation}${offset}`,
      init
    ],
    fetcherFullRequest
  )
  const mustReadNewsArticlesRaw = (data?.data ?? []).map((article: any) => ({
    ...article,
    field_news_must_read: true
  }))
  const mustReadNews = useMemo(
    () =>
      data ? transferNewsArticles(mustReadNewsArticlesRaw, data.included) : [],
    [data]
  )
  return {
    mustReadNews: mustReadNews ? mustReadNews : data,
    next: data && !!data?.links?.next,
    ids: data
      ? transferNewsArticles(data?.data, data.included)
          ?.map(
            ({ id, title, commentCount, likes: { likes, dislikes }, lead }) =>
              `${id}-${title}-${commentCount}-${likes}-${dislikes}-${lead}`
          )
          ?.toString()
      : data,
    isLoading: !error && !data,
    isError: error
  }
}

export const updateNewsViewers = async (id: string) => {
  if (id) {
    const response = await fetch(`${API_DOMAIN}/news/trackview/${id}`, {
      ...init,
      headers: {
        ...init.headers,
        'Content-Type': 'application/vnd.api+json'
      },
      method: 'POST'
    })
    const data = await response.json()
    return data
  }
}

export function patchNewsArticle(
  id: string | undefined,
  lang: string,
  file: File | undefined,
  fileMeta: FileMeta,
  { category, ...attributes }: Attributes,
  mainImageData?: {
    uuid: string
    vid: string
  },
  additionalRelationships?: Record<string, Record<string, unknown>>
) {
  const ImageDataRelationships =
    mainImageData && mainImageData.vid && mainImageData.uuid
      ? convertImageDataToRelationship(mainImageData.uuid, mainImageData.vid)
      : {}
  const relationships = {
    ...ImageDataRelationships,
    ...additionalRelationships
  }
  const langQuery = lang === 'en' ? '' : `/${lang}`
  return file
    ? patchNewsArticleWithFile(
        lang,
        {
          ...attributes,
          category,
          field_news_author: additionalRelationships?.field_news_author || ''
        },
        file,
        fileMeta,
        id
      )
    : id
      ? fetch(
          `${API_DOMAIN}${langQuery}/jsonapi/node/news/${id}?include=field_media`,
          {
            ...init,
            headers: {
              ...init.headers,
              'Content-Type': 'application/vnd.api+json'
            },
            method: 'PATCH',
            body: JSON.stringify({
              data: {
                type: NEWS_ARTICLE_TYPE,
                id: id,
                attributes: {
                  ...attributes
                },
                relationships
              }
            })
          }
        )
      : addNewsArticle(lang, attributes, relationships)
}

export function addNewsArticle(
  lang: string,
  attributes: Attributes,
  relationships: Relationship | Record<string, unknown>
) {
  const langQuery = lang === 'en' ? '' : `/${lang}`
  return fetch(`${API_DOMAIN}${langQuery}/jsonapi/node/news`, {
    ...init,
    headers: {
      ...init.headers,
      'Content-Type': 'application/vnd.api+json'
    },
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: NEWS_ARTICLE_TYPE,
        attributes: {
          ...attributes
        },
        relationships
      }
    })
  })
}

export function patchNewsArticleWithFile(
  lang: string,
  attributes: Attributes,
  file: File,
  fileMeta: Record<string, string | string[]>,
  id?: string
) {
  const fileMetaPrepared: Record<string, (string | string[])[]> = {}
  Object.keys(fileMeta).forEach((key) => {
    fileMetaPrepared[key] = [fileMeta[key]]
  })

  const formData = new FormData()

  for (const name in attributes) {
    switch (name) {
      case 'field_text':
      case 'field_lead':
        formData.append(
          name,
          (attributes[name] as Record<string, string>).value
        )
        break
      case 'field_news_author':
        attributes[name] &&
          formData.append(
            name,
            (attributes[name] as Record<string, Record<string, string>>)?.data
              ?.id
          )
        break
      case 'field_sent_to_review':
        attributes[name] &&
          formData.append(
            name,
            (attributes[name] as string)?.replace('+00:00', '')
          )
        break
      default:
        formData.append(name, attributes[name] as string)
    }
  }

  const filetype = getFileType(file)

  const formattedFileName = formatFileName(file.name)

  formData.append('file', file, formattedFileName)
  formData.append('profile', UploadProfiles.newsUploads)
  formData.append(
    'metadata',
    JSON.stringify({
      fields: { ...fileMetaPrepared, filetype: [filetype] }
    })
  )

  const url = id ? `/update/${id}` : '/create'

  return fetch(`${API_DOMAIN}/news${url}`, {
    ...init,
    method: 'POST',
    body: formData
  })
}

export function transferNewsArticle(
  { attributes, id, relationships }: any = {},
  included: any[]
): NewsArticle {
  if (!attributes) {
    return {
      id: '',
      drupalInternalNid: '',
      date: '',
      shortDate: '',
      title: '',
      langOrigin: '',
      lang: '',
      lead: '',
      text: '',
      commentCount: 0,
      media: { meta: getNewlyAddedFileMetaInitialState() },
      status: true,
      publishDate: '',
      unpublishDate: '',
      author: undefined,
      editor: undefined,
      viewers: 0,
      reviewer: undefined,
      mediaType: '',
      previewMedia: '',
      location: [],
      categories: [],
      channels: [],
      likes: {
        likes: '',
        dislikes: '',
        clickedBy: null
      },
      reviewFeedback: '',
      reviewNote: '',
      isMustRead: false,
      mustReadResetDate: undefined,
      likesDisabled: false
    }
  }
  const editor = included?.find((a) => a.id === relationships?.uid?.data?.id)
  const author =
    included?.find(
      (a) => a.id === relationships?.field_news_author?.data?.id
    ) ?? editor
  const reviewer = included?.find(
    (a) => a.id === relationships?.field_news_reviewer?.data?.id
  )
  const viewers = attributes?.field_computed_viewers ?? 0
  const mediaFile = included?.find(
    (asset) => relationships?.field_media?.data[0]?.id === asset?.id
  )
  const media = mediaFile?.attributes?.field_image_original
  const type = mediaFile?.type
  const previewMedia = getMediaPreview(mediaFile)
  let isMustRead: boolean
  if (typeof attributes.field_news_must_read === 'string') {
    isMustRead = attributes.field_news_must_read === '1'
  } else {
    isMustRead = !!attributes.field_news_must_read
  }
  return {
    id,
    drupalInternalNid: attributes.drupal_internal__nid,
    date: formatArticleDate(attributes.published_at ?? attributes.changed),
    shortDate: formatArticleDate(
      attributes.published_at ?? attributes.changed,
      true
    ),
    title: attributes.title,
    langOrigin:
      attributes.content_translation_source === UND_PHP
        ? attributes.langcode
        : attributes.content_translation_source,
    lang: attributes.langcode,
    lead: attributes?.field_lead?.value,
    text: attributes?.field_text?.value,
    commentCount: attributes?.comment?.comment_count ?? 0,
    media: {
      url: media?.replace('&download=true', ''),
      meta: getNewlyAddedFileMetaInitialState()
    },
    status: attributes.status,
    publishDate: attributes.publish_on,
    unpublishDate: attributes.unpublish_on,
    author: author && transferUser(author, included),
    editor: editor && transferUser(editor, included),
    viewers,
    reviewer: reviewer && transferUser(reviewer, included),
    mediaType: type,
    previewMedia: previewMedia,
    likesDisabled:
      typeof attributes.field_disable_like === 'string'
        ? attributes.field_disable_like === '1'
        : attributes.field_disable_like,
    location: attributes.field_location || [],
    categories: attributes.field_news_category || [],
    channels:
      (Array.isArray(attributes.field_news_channel)
        ? attributes.field_news_channel
        : [attributes.field_news_channel]) ?? [],
    reviewState: attributes.field_review_state,
    likes: {
      likes: attributes?.field_likes?.likes,
      dislikes: attributes?.field_likes?.dislikes,
      clickedBy: attributes?.field_likes?.clicked_by
    },
    reviewFeedback: attributes.field_news_review_feedback,
    reviewNote: attributes.field_news_note_for_review,
    isMustRead
  }
}

export function getMediaPreview(mediaFile: any = {}) {
  return MEDIA_TYPES.video.includes(mediaFile?.type)
    ? mediaFile?.attributes?.acquia_dam_embeds?.value?.video_poster?.url
    : MEDIA_TYPES.image.includes(mediaFile?.type)
      ? mediaFile?.attributes?.field_image_original
      : ''
}

export function transferNewsArticles(
  data: any[],
  included: any[]
): NewsArticle[] {
  return [...(data || [])]?.map((article) =>
    transferNewsArticle(article, included)
  )
}

export function setLike(articleId: string, type?: string) {
  const url =
    type === COMMENT_TYPE
      ? `${API_DOMAIN}/comment/like?cid=${articleId}`
      : `${API_DOMAIN}/news/like?nid=${articleId}`
  return fetch(url, {
    method: 'POST',
    ...init
  })
}

export function useNewsCategories() {
  const { contentLang } = useConfigStore.getState()
  const langQuery = contentLang === 'en' ? '' : `/${contentLang}`

  const { data, error } = useSWRImmutable(
    [`${API_DOMAIN}${langQuery}/news_api/categories`, init],
    fetcherFullRequest
  )

  return {
    categories: data,
    isLoading: !error && !data,
    isError: error
  }
}

export type SpaceUpdate = {
  type: typeof SPACE_ARTICLE_TYPE | typeof SUBSPACE_TYPE
  id: string
  space?: string
  images: Record<string, { url: string }>
  title: string
  date: string
  category?: string
}

export function useSpaceUpdates() {
  const { contentLang } = useConfigStore.getState()
  const langQuery = contentLang === 'en' ? '' : `/${contentLang}`

  const { data, error } = useSWRImmutable(
    [`${API_DOMAIN}${langQuery}/space-updates?page[limit]=4`, init],
    fetcherFullRequest
  )

  return {
    updates: data?.data?.map(
      (node: {
        id: string
        attributes: Record<string, any>
        relationships: Record<string, any>
        type: typeof SPACE_ARTICLE_TYPE | typeof SUBSPACE_TYPE
      }) => {
        if (node?.type === SPACE_ARTICLE_TYPE) {
          return {
            type: SPACE_ARTICLE_TYPE,
            id: node.id,
            images: node.attributes?.acquia_dam_embeds?.value,
            title: node.attributes?.title,
            date: node.attributes?.changed
          }
        } else {
          return {
            type: SUBSPACE_TYPE,
            id: node.id,
            space: node?.relationships?.field_space_category?.data?.id,
            images: node.attributes?.acquia_dam_embeds?.value,
            title: node.attributes?.title,
            date: node.attributes?.changed
          }
        }
      }
    ),
    isLoading: !error && !data,
    isError: error
  }
}
