import type { MouseEvent } from 'react'
import { Body5, Box } from '@sefar/design-system'
import type * as Stitches from '@stitches/react'

export const LocationBadge = ({
  locationName,
  onClick,
  css
}: {
  locationName?: string
  onClick?: () => void
  css?: Stitches.CSS
}) => {
  if (!locationName) {
    return null
  }

  const onClickHandle = (e: MouseEvent) => {
    e.preventDefault()
    onClick?.()
  }

  return (
    <Box
      css={{
        px: '3px',
        py: '1px',
        borderRadius: '$2',
        border: '1px solid $neutralLighten80',
        w: 'fit-content',
        h: 'fit-content',
        opacity: 1,
        '&:hover': {
          background: '#CAC9C9',
          border: '1px solid $neutralLighten80'
        },
        ...css
      }}
      onClick={onClickHandle}
    >
      <Body5
        fontWeight="bold"
        css={{
          color: 'rgb(0, 0, 0, 0.8)',
          fontSize: '10px',
          lineHeight: '15px'
        }}
      >
        {locationName}
      </Body5>
    </Box>
  )
}
