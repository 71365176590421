import {
  Body3,
  Box,
  Checkbox,
  ChevronLeftIcon16,
  Container,
  ContainerPadding,
  DateRangeDateOutput,
  Space,
  Spinner,
  ToastTypeEnum,
  useToastContext
} from '@sefar/design-system'
import {
  NewsArticleSettings,
  NewsDetailsSettings
} from './news-details-settings'
import {
  MANDATORY_FIELDS_INFO,
  NewsDetailsActions,
  saveNewsArticle
} from './news-details-actions'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { ArticleEditor } from '../../components'
import { ChannelsEnum, NewsArticle, UploadProfiles } from '../../api'
import { useMedia } from 'react-use'
import { BREAKPOINTS_QUERIES, styled } from '../../../../stitches.config'
import { myContentPageUrl } from '../../app'
import { useNavigate } from 'react-router-dom'
import { useLocationStateFrom } from '../../hooks/useLocationStateFrom'
import { useConfigStore } from '../../state/config'
import { NewsEditorNote } from './news-editor-note'

const StyledSaveAndCloseBtn = styled('button', {
  d: 'flex',
  alignItems: 'center',
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  ':disabled': {
    color: '$neutralLighten60',
    cursor: 'not-allowed',
    pointerEvents: 'none'
  }
})

export function NewsDetailsEdit({
  article,
  lang,
  setArticle,
  onArticleChange,
  isNew
}: {
  article: NewsArticle
  lang: string
  setArticle: Dispatch<SetStateAction<NewsArticle>>
  onArticleChange?: () => Promise<void>
  isNew: boolean
}) {
  const { me } = useConfigStore()
  const from = useLocationStateFrom([], () => `/${myContentPageUrl}`)
  const setToast = useToastContext()
  const navigate = useNavigate()
  const isSm = useMedia(BREAKPOINTS_QUERIES.sm)

  const [requestInProgress, setRequestInProgress] = useState<boolean>(false)
  const [isEditorFocused, setIsEditorFocused] = useState<boolean>(false)
  const [publicationDates, setPublicationDates] =
    useState<DateRangeDateOutput>()

  useEffect(() => {
    setPublicationDates({
      start: article?.publishDate ? new Date(article?.publishDate) : undefined,
      end: article?.unpublishDate ? new Date(article?.unpublishDate) : undefined
    })
  }, [article?.unpublishDate, article?.publishDate])

  const [settings, setSettings] = useState<NewsArticleSettings>({
    langcode: 'en',
    field_location: [],
    field_news_category: [],
    field_news_must_read: false,
    field_disable_like: false,
    field_news_must_read_reset_time: undefined,
    unpublishOn: undefined,
    channelType: undefined
  })

  const [isReadyToSave, setIsReadyToSave] = useState<boolean>(false)

  useEffect(() => {
    setSettings((oldValue) => ({
      ...oldValue,
      field_location: article?.location,
      field_disable_like: article?.likesDisabled,
      field_news_category: (article?.categories as string[]) ?? [],
      unpublishOn: article?.unpublishDate ?? undefined,
      channelType: article?.channels?.[0] as ChannelsEnum,
      field_news_author: article?.author ?? me,
      field_news_must_read: article?.isMustRead ?? false,
      field_news_must_read_reset_time: article?.mustReadResetDate
    }))
  }, [
    article?.location,
    article?.categories,
    article?.channels,
    article?.isMustRead,
    article?.unpublishDate,
    me?.id
  ])

  const onSaveAndCancelButtonCLick = () => {
    setRequestInProgress(true)
    saveNewsArticle(
      article,
      settings,
      undefined,
      settings.unpublishOn
        ? {
            start: undefined,
            end: new Date(settings.unpublishOn)
          }
        : undefined
    )
      .then((res) => {
        if (res?.errors?.length) {
          setToast({
            message: 'Some error occurred. Please try again later!',
            type: ToastTypeEnum.error
          })
        } else {
          setToast({
            message: 'The article was successfully created!',
            type: ToastTypeEnum.success
          })
          navigate(`/${myContentPageUrl}`)
        }
      })
      .finally(() => {
        setRequestInProgress(false)
      })
  }

  useEffect(() => {
    if (
      lang &&
      settings?.channelType &&
      !requestInProgress &&
      article?.text &&
      article?.title &&
      article?.lead &&
      article?.media?.url
    ) {
      setIsReadyToSave(true)
    } else {
      setIsReadyToSave(false)
    }
  }, [
    lang,
    settings?.channelType,
    requestInProgress,
    article?.media?.url,
    article
  ])

  useEffect(() => {
    if (article?.lang && article?.lang !== settings?.langcode) {
      setSettings((oldValue) => ({
        ...oldValue,
        langcode: article?.lang
      }))
    }
  }, [article?.lang])

  return (
    <>
      {requestInProgress && (
        <Space
          pt="4XL"
          css={{
            position: 'absolute',
            top: 'auto',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            background: '$scrim',
            zIndex: '$tooltip'
          }}
        >
          <Spinner />
        </Space>
      )}

      {isSm && (
        <Box
          css={{
            position: 'fixed',
            top: '0',
            width: '100%',
            background: '$white',
            height: '57px',
            left: '0',
            zIndex: 100,
            boxShadow: '0 8px 4px 0 rgba(0,0,0,0.04)'
          }}
        >
          <Container css={{ height: '100%' }}>
            <ContainerPadding css={{ height: '100%' }}>
              <Box
                css={{
                  d: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '$4',
                  height: '100%'
                }}
              >
                <StyledSaveAndCloseBtn
                  data-cy="saveAndCloseButton"
                  onClick={() => {
                    if (isReadyToSave) {
                      onSaveAndCancelButtonCLick()
                    } else if (isSm) {
                      setToast({
                        type: ToastTypeEnum.warning,
                        message: MANDATORY_FIELDS_INFO
                      })
                    }
                  }}
                >
                  <Box
                    as="span"
                    css={{
                      d: 'flex',
                      alignItems: 'center',
                      mr: '0.5rem'
                    }}
                  >
                    <ChevronLeftIcon16 />
                  </Box>
                  <Body3>
                    {article?.status ? 'Save & Publish' : 'Save & Close'}
                  </Body3>
                </StyledSaveAndCloseBtn>
              </Box>

              {/*<Space ml="LG">*/}
              {/*  <Settings20 />*/}
              {/*</Space>*/}
            </ContainerPadding>
          </Container>
        </Box>
      )}
      <ArticleEditor
        article={article}
        isEditorFocused={isEditorFocused}
        onArticleChange={(value) =>
          setArticle((oldValue) => ({ ...oldValue, ...value }))
        }
        onEditorFocusChange={setIsEditorFocused}
        uploadProfile={UploadProfiles.newsUploads}
        contentType="news"
      ></ArticleEditor>

      <Space>
        <NewsEditorNote note={article?.reviewNote} />
      </Space>
      <Space
        css={{
          backgroundColor: '$neutralLighten97',
          padding: '$8 0',
          '@sm': { mb: '$3' }
        }}
      >
        <Container>
          <ContainerPadding>
            <Box css={{ d: 'flex', padding: '0 $4' }}>
              <Checkbox
                checked={settings.field_disable_like}
                onCheckedChange={(checked) => {
                  if (typeof checked === 'boolean')
                    setSettings((prev) => ({
                      ...prev,
                      field_disable_like: checked
                    }))
                }}
              />
              <Body3 fontWeight="bold" css={{ ml: '$2' }}>
                Hide likes
              </Body3>
            </Box>
          </ContainerPadding>
        </Container>
      </Space>
      <Space css={{ '@sm': { mb: '$3' } }}>
        <NewsDetailsSettings
          isNew={isNew}
          settings={settings}
          onSettingsUpdate={(update) =>
            setSettings((oldValue) => ({ ...oldValue, ...update }))
          }
          publicationDates={publicationDates}
          setPublicationDates={setPublicationDates}
        />
      </Space>
      <Box
        css={{
          display: isSm && isEditorFocused ? 'none' : 'block',
          position: 'fixed',
          bottom: '0',
          width: '100%',
          background: '$neutralLighten20',
          height: '$inlineEditingBottomToolbar',
          left: '0',
          zIndex: 100
        }}
      >
        <Container css={{ height: '100%' }}>
          <ContainerPadding css={{ height: '100%' }}>
            <NewsDetailsActions
              article={article}
              settings={settings}
              onCancelButtonCLick={() => navigate(from)}
              setRequestInProgress={setRequestInProgress}
              isReadyToSave={isReadyToSave}
              onArticleChange={onArticleChange}
              publicationDates={publicationDates}
              setPublicationDates={setPublicationDates}
            />
          </ContainerPadding>
        </Container>
      </Box>
    </>
  )
}
